import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { COLORS3, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import { TitleMedium2 } from '@zola/zola-ui/src/typography/Headings';

import styled from '@emotion/styled';

import CachedImage from '../../images/CachedImage';
import InquiryButton from '../inquiries/InquiryButton';

export const CLOSE_LIGHTBOX_ICON_SIZE = 24;
const TITLE_BAR_V_PADDING = 20;
const TITLE_BAR_DESKTOP_H_PADDING = 40;
const TITLE_BAR_BORDER = 1;
const TITLE_BAR_CONTENT_HEIGHT = 44;
const IMAGE_PREVIEW_HEIGHT = 90;
const IMAGE_PREVIEW_WIDTH = 100;
const IMAGE_PREVIEW_BORDER = 2;
const IMAGE_PREVIEW_PADDING = 4;
export const IMAGE_PREVIEW_IMAGE_HEIGHT =
  IMAGE_PREVIEW_HEIGHT - IMAGE_PREVIEW_BORDER - IMAGE_PREVIEW_PADDING * 2;
export const IMAGE_PREVIEW_IMAGE_WIDTH =
  IMAGE_PREVIEW_WIDTH - IMAGE_PREVIEW_BORDER - IMAGE_PREVIEW_PADDING * 2;

/**  Margin above the image preview */
const IMAGE_PREVIEW_MARGIN = 8;
/** Margin above the image scrollbar */
const IMAGE_PREVIEW_SCROLLBAR_MARGIN = 8;
/** Height of the scrollbar.  If you need that comment, please pay attention when given directions by flight attendants on how to use a seatbelt. */
const IMAGE_SCROLLBAR_HEIGHT = 8;

const TITLE_BAR_HEIGHT = TITLE_BAR_V_PADDING * 2 + TITLE_BAR_CONTENT_HEIGHT + TITLE_BAR_BORDER;
const MODAL_CONTAINER_PADDING = 48;

export const Lightbox = styled.div<{ device: 'mobile' | 'notMobile' }>`
  position: absolute;
  z-index: 99999;
  width: 100vw;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS3.BLACK_100};
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  ${MEDIA_QUERY_V2.MOBILE} {
    ${({ device }) => device === 'notMobile' && 'display: none;'}
  }

  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    ${({ device }) => device === 'mobile' && 'display: none;'}
  }
`;

export const LightboxImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const CloseLightboxBtn = styled.button`
  padding: 0;
  outline: none;
  border: none;
  background: none;
  color: ${COLORS3.WHITE_100};
  z-index: ${ZINDEX.middle};
  height: ${CLOSE_LIGHTBOX_ICON_SIZE}px;
  &:focus {
    outline: highlight auto 2px;
    outline: -webkit-focus-ring-color auto 2px;
  }

  ${MEDIA_QUERY_V2.MOBILE} {
    margin-left: auto;
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  background-color: ${COLORS3.BLACK_100};
  position: fixed;
  margin: 0 auto;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    height: 100vh;
    overflow-y: auto;
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    position: relative;
    max-height: 100vh;
  }
`;

export const SlideshowContainer = styled.div`
  position: relative;
  padding: ${MODAL_CONTAINER_PADDING}px 24px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .slick-track {
    display: flex;
    align-items: center;
    /**
     * Because .slick-track is transformed 3d, we need to set a max-height on it to prevent the image from being too large on smaller screens;
    * - height of the header
    * - padding on the container
    * - height of the image preview track
    * - height of the scrollbar
    */
    height: min(
      calc(
        100vh - ${TITLE_BAR_HEIGHT}px - ${MODAL_CONTAINER_PADDING * 2}px -
          ${IMAGE_PREVIEW_HEIGHT + IMAGE_PREVIEW_MARGIN}px - ${IMAGE_SCROLLBAR_HEIGHT}px -
          ${IMAGE_PREVIEW_SCROLLBAR_MARGIN}px
      ),
      750px
    );
  }

  .slick-slide div {
    height: 100%;
  }

  ${MEDIA_QUERY_V2.TABLET} {
    min-height: 750px;
  }
`;

export const CarouselImage = styled(CachedImage)`
  max-width: 100%;
  max-height: 750px;
  margin: 0 auto;

  ${MEDIA_QUERY_V2.TABLET} {
    max-height: 688px;
  }
`;

export const ImgContainer = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERY_V2.TABLET} {
    min-height: 688px;
  }
`;

export const LightboxCaption = styled.p`
  color: ${COLORS3.WHITE_100};
  margin-top: ${SPACING.S32};
  ${MEDIA_QUERY_V2.DESKTOP_LG} {
    margin-top: -40px;
  }

  &:empty {
    display: none;
  }
`;

export const ScreenReaderText = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const MobileContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: ${SPACING.XS};
  padding-bottom: 93px; // height of inquiry module + gap
`;

export const MobileSlideShow = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${COLORS3.BLACK_100};
  display: flex;
  align-items: center;
`;

export const TitleBar = styled.div`
  border-bottom: ${TITLE_BAR_BORDER}px solid ${COLORS3.BLACK_030};
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${TITLE_BAR_V_PADDING}px ${TITLE_BAR_DESKTOP_H_PADDING}px;
  background-color: ${COLORS3.BLACK_100};

  ${MEDIA_QUERY_V2.MOBILE} {
    position: sticky;
    top: 0;
    padding: ${TITLE_BAR_V_PADDING}px 16px;
  }
`;

export const StyledTitle2 = styled(TitleMedium2)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImagePreviewItem = styled.span`
  width: ${IMAGE_PREVIEW_WIDTH}px;
  height: ${IMAGE_PREVIEW_HEIGHT}px;
  display: flex;
  align-items: center;
  border: ${IMAGE_PREVIEW_BORDER}px solid transparent; // to avoid a shift when active
  margin-bottom: ${IMAGE_PREVIEW_SCROLLBAR_MARGIN}px;
  padding: ${IMAGE_PREVIEW_PADDING}px;
`;

export const ImagePreviewListWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImagePreviewList = styled.ul`
  display: flex;
  overflow-x: auto;
  gap: ${SPACING.S10};
  margin-top: ${IMAGE_PREVIEW_MARGIN}px;

  li {
    margin: unset !important;
  }

  .slick-active ${ImagePreviewItem} {
    border-color: ${COLORS3.WHITE_100};
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    height: ${IMAGE_SCROLLBAR_HEIGHT}px;
    background: ${COLORS3.BLACK_010};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: ${COLORS3.BLACK_030};
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    &.leftScrim {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10%;
        height: ${IMAGE_PREVIEW_HEIGHT}px + ${IMAGE_SCROLLBAR_HEIGHT}px;
        background: linear-gradient(
          to right,
          ${COLORS3.WHITE_100} 0%,
          rgba(255, 255, 255, 0.634501) 32.58%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }
    }

    &.rightScrim {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10%;
        height: ${IMAGE_PREVIEW_HEIGHT}px + ${IMAGE_SCROLLBAR_HEIGHT}px;
        background: linear-gradient(
          to left,
          ${COLORS3.WHITE_100} 0%,
          rgba(255, 255, 255, 0.634501) 32.58%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }
    }
  }
`;

export const CaretContainerButton = styled.button<{ position: 'left' | 'right' }>`
  outline: none;
  box-shadow: none;
  align-items: center;
  border: 1px solid ${COLORS3.BLACK_100};
  background: ${COLORS3.CLOUD_100};
  border-radius: 100px;
  color: ${COLORS3.BLACK_100};
  display: flex !important;
  align-items: center;
  flex-direction: row;
  height: 44px;
  width: 44px;
  justify-content: center;
  padding: 0;
  top: 0;
  bottom: 0;
  ${({ position }) => `${position}: ${SPACING.S16};`};
  position: absolute;
  z-index: ${ZINDEX.middle};
  margin: auto 0;

  &:hover {
    background-color: ${COLORS3.BLACK_005};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`;

const DESKTOP_BUTTON_WIDTH = `200px`;

export const LightboxInquiryButton = styled(InquiryButton)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;
export const DeclineFirstMoveButton = styled(ButtonV3)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;
export const ExploreMoreButton = styled(ButtonV3)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;

export const TitleBarLeft = styled.div<{ withReviewInfo?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ withReviewInfo }) => withReviewInfo && `align-items: flex-start;`}
`;
